import { AdminPagesStateModel } from "../../admin.state";

export class BerthsStateModel extends AdminPagesStateModel {
  items: Berth[];
}

export interface Berth {
  id?: string;
  code: string;
  name: string;
}