import { Berth } from "./berths.model";

export class LoadBerths {
  public static readonly type = "[Berths] load berths";
}

export class AddBerth {
  public static readonly type = "[Berths] add berth";
  constructor(
    public payload: Berth,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateBerth {
  public static readonly type = "[Berths] update berth";
  constructor(
    public payload: Berth,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveBerth {
  public static readonly type = "[Berths] remove berth";
  constructor(
    public berthId: string
  ) {}
}
