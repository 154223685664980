import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { Berth } from "./state/berths.model";

@Injectable({
  providedIn: "root"
})
export class BerthsService {
  private apiUrl: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getBerths() {
    const params: HttpParams = new HttpParams({
      fromObject: {
        MaxResultCount: 50
      }
    });
    return this.http.get<any>(`${this.apiUrl}/app/berth`, { params });
  }

  update(berth: Berth) {
    return this.http.put<any>(`${this.apiUrl}/app/berth/${berth.id}`, berth);
  }

  create(berth: Berth) {
    return this.http.post<any>(`${this.apiUrl}/app/berth`, berth);
  }

  remove(berthId: string) {
    return this.http.delete(`${this.apiUrl}/app/berth/${berthId}`);
  }
}
