import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { LoadBerthsAsOptions } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { BerthsService } from "../berths.service";
import { AddBerth, LoadBerths, RemoveBerth, UpdateBerth } from "./berths.actions";
import { BerthsStateModel } from "./berths.model";

@State<BerthsStateModel>({
  name: "berths",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class BerthsState {
  
  constructor(
    private berthsService: BerthsService
  ) {}

  @Selector() static berths(state: BerthsStateModel) { return state.items; }
  @Selector() static loading(state: BerthsStateModel) { return state.loading; }
  @Selector() static saving(state: BerthsStateModel) { return state.saving; }

  @Action(LoadBerths)
  loadBerths(ctx: StateContext<BerthsStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });
    
    return this.berthsService.getBerths().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });
          ctx.dispatch(
            new LoadBerthsAsOptions()
          );
        },
        (error) => {
          ctx.patchState({ loading: false });
          ctx.dispatch(new SetError({ loading: error }));
        }
      )
    );
  }

  @Action(AddBerth)
  addBerth(ctx: StateContext<BerthsStateModel>, action: AddBerth) {
    ctx.patchState({ saving: true });
    
    return this.berthsService.create(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: [response, ...state.items],
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(UpdateBerth)
  updateBerth(ctx: StateContext<BerthsStateModel>, action: UpdateBerth) {
    ctx.patchState({ saving: true });

    return this.berthsService.update(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(RemoveBerth)
  removeBerth(ctx: StateContext<BerthsStateModel>, action: RemoveBerth) {
    return this.berthsService.remove(action.berthId).pipe(
      tap(
        () => {
          const state = ctx.getState();
          ctx.patchState({
            items: state.items.filter(
              (item) => {
                return item.id !== action.berthId;
              }
            )
          });
        }
      )
    );
  }
}
